import * as React from "react";
import { Layout, PageTitle, Concept } from "../components";
import Seo from "../components/Seo";

const ConceptPage = () => {
  return (
    <Layout>
      <Seo title="CONCEPT - emo TOKYOについて" />
      <PageTitle title="CONCEPT" subTitle="emo TOKYOについて" />
      <Concept />
    </Layout>
  );
};

export default ConceptPage;
